import React, {useEffect, useState} from 'react'
import { getAverageMemberRevenue, getTransactingMemberCount } from '../../utils/functions'
import { useAsyncValue } from '../../hooks/useAsyncData'
import { toUsd } from '../../utils/number'

const FooterCard = ({ title, values }) => {
	return (
		<div className="footer-card d-flex flex-column align-items-center">
			<div className="title">
				<span className="text-uppercase">{title}</span>
			</div>
			<div className="value">
				{
					values.map((x, i) => {
						return (
							<span key={`${x}-${i}`} className="value-block">{x}</span>
						)
					})
				}
			</div>
		</div>
	)
}

const LiveClientCountCard = () => {
	const value = useAsyncValue(getTransactingMemberCount)
	const [values, setValues] = useState([])

	useEffect(() => {
		setValues(value.toString().split(''))
	}, [value])

	return (
		<FooterCard title="Live Transacting Clients" values={values}/>
	)
}

const AverageRevenueCard = () => {
	const value = useAsyncValue(getAverageMemberRevenue)
	const [values, setValues] = useState([])

	useEffect(() => {
		setValues([toUsd(value.toString(), 0)])
	}, [value])

	return (
		<FooterCard title="Average MRR" values={values}/>
	)
}

const FooterCards = () => {
	return (
		<div className="footer-card-container d-flex flex-row w-100 justify-content-end">
			<div className="pr-3">
				<LiveClientCountCard/>
			</div>
			<div>
				<AverageRevenueCard/>
			</div>
		</div>
	)
}

export default FooterCards
