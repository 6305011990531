import { useEffect, useState } from 'react'
import DashboardLayout from '../../components/Dashboard/DashboardLayout'
import { UserManager } from 'oidc-client'

const oidcSettings = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
}

const userManager = new UserManager(oidcSettings)

export default function Home () {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    userManager.getUser()
      .then(user => {
        if (user) {
          setIsAuthenticated(true)
        }

        if (!user) {
          userManager.signinRedirect()
            .then(null)
        }
      })
  }, [])

  return (
    <>
      {
        isAuthenticated && (
          <DashboardLayout />
        )
      }
    </>
  )
}
