import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import _ from 'underscore'
import { PerformanceCard, PerformanceCardValue } from '../Card/PerformanceCard'
import { toPosition, toUsd } from '../../utils/number'
import { animated, useTransition, useSpring } from 'react-spring'
import { useCountUp } from 'react-countup'
import { continentColorScales } from '../../utils/chart'
import useRegionData from '../../hooks/useRegionData'
import { ValueIcon } from './ValueIcon'

const RegionCardValue = ({ title, value, newValue, compareValue }) => {
  const { countUp, update } = useCountUp({ end: value, separator: ',' })

  useEffect(() => {
    update(value)
  }, [value, update])

  return (
    <>
      <PerformanceCardValue
        title={title}
        value={`(${toUsd(newValue)}) ${toUsd(countUp.toString())}`}
        valueIcon={<ValueIcon value={value} compareValue={compareValue}/>}
      />
    </>
  )
}

const RegionCard = ({ position, region, index, previousData, ...rest }) => {
  const {
    name, code, weekTotal, monthTotal, yearTotal,
    previousMonthTotal, previousWeekTotal, weekTotalNew, monthTotalNew, yearTotalNew
  } = region
  const currentRegion = previousData[index]?.name
  const hasChanged = currentRegion && currentRegion !== name
  const previousIndex = _.findIndex(previousData, x => x.name === name)
  const movedUp = hasChanged && index < previousIndex
  const movedDown = hasChanged && index > previousIndex

  const defaultColor = 'black'
  const headerColor = continentColorScales[code][1]

  const props = useSpring({
    from: { color: defaultColor, zIndex: 1 },
    to: async next => {
      let background = defaultColor

      if (movedUp) {
        background = '#09c202'
      }

      if (movedDown) {
        background = '#a60d02'
      }

      await next({ color: background, zIndex: 100 })
      await next({ color: defaultColor, zIndex: 1 })
    }
  })

  return (
    <PerformanceCard
      title={name}
      position={toPosition(position)}
      inverse
      headerColor={headerColor}
      {...rest}
    >
      <animated.div style={props} className="animated-card">
        <div className="d-flex flex-column justify-content-around h-100">
          <RegionCardValue title="WTD" value={weekTotal} newValue={weekTotalNew} compareValue={previousWeekTotal}/>
          <RegionCardValue title="MTD" value={monthTotal} newValue={monthTotalNew} compareValue={previousMonthTotal}/>
          <RegionCardValue title="YTD" value={yearTotal} newValue={yearTotalNew}/>
        </div>
      </animated.div>
    </PerformanceCard>
  )
}

const defaultCardHeight = 130

const RegionCards = () => {
  const [cardHeight, setCardHeight] = useState(defaultCardHeight)
  const [sortedData, setSortedData] = useState([])
  const [dataLength, setDataLength] = useState(0)
  const [containerNode, setContainerNode] = useState(null)
  const containerRef = useCallback(node => {
    if (node) {
      setContainerNode(node)
    }
  }, [])
  const { data } = useRegionData()

  const previousDataRef = useRef([])

  useEffect(() => {
    previousDataRef.current = sortedData
  })

  const previousData = previousDataRef.current

  useEffect(() => {
    if (data.length) {
      setSortedData(data)
      setDataLength(data.length)
    }
  }, [data])

  useLayoutEffect(() => {
    if (containerNode) {
      const updateCardHeight = () => {
        const height = containerNode.getBoundingClientRect().height
        setCardHeight(curr => {
          const cardHeightTotal = dataLength * curr
          const nextCardSpacing = (height - cardHeightTotal) / dataLength

          let nextCardHeight = defaultCardHeight + nextCardSpacing

          if (nextCardHeight < defaultCardHeight) {
            nextCardHeight = defaultCardHeight
          }

          if (nextCardSpacing !== Infinity) {
            return nextCardHeight
          }

          return curr
        })
      }

      if (dataLength > 0) {
        updateCardHeight()
      }

      window.addEventListener('resize', updateCardHeight)

      return () => {
        window.removeEventListener('resize', updateCardHeight)
      }

    }
  }, [containerNode, dataLength])

  const transitions = useTransition(
    sortedData.map((item, i) => ({ ...item, y: i * cardHeight, height: cardHeight })),
    d => d.name,
    {
      from: { height: 0 },
      leave: { height: 0 },
      enter: ({ y, height }) => {
        return {
          y,
          height,
          opacity: 1
        }
      },
      update: ({ y, height }) => ({ y, height }),
    }
  )

  return (
    <div className="d-flex flex-column justify-content-between region-transition-container" ref={containerRef}>
      {
        transitions.map(({ item, props: { y, ...rest }, key }, index) => {
          const zIndex = data.length - index
          return (
            <animated.div
              key={key}
              className="card-wrapper"
              style={{
                zIndex: zIndex,
                transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
                ...rest
              }}
            >
              <RegionCard
                position={index + 1}
                region={item}
                index={index}
                previousData={previousData}
                className="card"
              />
            </animated.div>
          )
        })
      }
    </div>
  )
}

export { RegionCards }
