import React from 'react'
import classNames from 'classnames'

const PerformanceCard = (props) => {
	const { position, icon, title, children, inverse, headerColor } = props
	const headerStyle = {}

	if (headerColor) {
		headerStyle.color = headerColor
	}

	return (
		<div
			className={classNames('card', {
				'inverse': inverse
			})}
		>
			<div className="card-header">
				<div className="d-flex flex-row">
					<div className="pr-2 card-header-icon">
						{
							position
								? (
									<span>{position}</span>
								) : (
									<i className={classNames('tim-icons', icon)}/>
								)
						}
					</div>
					<div className="card-title" style={headerStyle}>{title}</div>
				</div>
			</div>
			<div className="card-body">
				{children}
			</div>
		</div>
	)
}

const HorizontalPerformanceCard = (props) => {
	const { icon, title, children } = props
	return (
		<div className="card">
			<div className="card-body">
				<div className="d-flex flex-row align-items-center h-100">
					<div className="d-flex justify-content-center card-header-icon h-100 align-items-center">
						<i className={classNames('tim-icons', icon)}/>
					</div>
					<div className="card-title pl-2 d-flex align-items-center">{title}</div>
					<div className="d-flex card-content pl-2 py-2">
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

const PerformanceCardValue = (props) => {
	const { title, value, valueIcon } = props
	return (
		<div className="d-flex flex-row justify-content-between text-uppercase pl-1 pr-1">
			<div className="performance-card-value-title">{title}</div>
			<div className="d-flex align-items-center">
				{
					(valueIcon) && (
						<span className="pr-2">{valueIcon}</span>
					)
				}
				<div>{value}</div>
			</div>
		</div>
	)
}

export { PerformanceCard, PerformanceCardValue, HorizontalPerformanceCard }
