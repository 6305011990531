import { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { VectorMap } from 'react-jvectormap'
import useMapData from '../../hooks/useMapData'

const PerformanceVectorMap = () => {
  const [mapHeight, setMapHeight] = useState(0)
  const [mapWidth, setMapWidth] = useState(0)
  const [mapNode, setMapNode] = useState()
  const [containerNode, setContainerNode] = useState()
  const { mapData, markerData } = useMapData()
  const mapRef = useCallback((node) => {
    if (node) {
      setMapNode(node)
    }
  }, [])
  const containerRef = useCallback(node => {
    if (node) {
      setContainerNode(node)
    }
  }, [])

  useEffect(() => {
    if (mapNode) {
      const mapObj = mapNode.getMapObject()
      mapObj.addMarkers(markerData)
      mapObj.series.regions[0].setValues(mapData)
    }
  }, [mapNode, mapData, markerData])

  useLayoutEffect(() => {
    if (containerNode) {
      const setMapSize = () => {
        const node = containerNode.getBoundingClientRect()
        const { width, height } = node
        setMapHeight(height)
        setMapWidth(width)
      }

      setMapSize()

      window.addEventListener('resize', setMapSize)

      return () => {
        window.removeEventListener('resize', setMapSize)
      }
    }
  }, [containerNode])

  return (
    <div className="w-100 h-100" ref={containerRef}>
      <VectorMap
        ref={mapRef}
        backgroundColor="#3b96ce"
        containerStyle={{
          width: mapWidth,
          height: mapHeight
        }}
        containerClassName="map"
        map="world_mill"
        series={{
          regions: [{
            normalizeFunction: 'polynomial'
          }]
        }}
        zoomOnScroll={false}
      />
    </div>

  )
}

export {PerformanceVectorMap}
