import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const ValueIcon = ({value, compareValue}) => {
	return useMemo(() => {
		if (value > compareValue) {
			return <FontAwesomeIcon icon={faArrowUp} color="green"/>
		}

		if (value < compareValue) {
			return <FontAwesomeIcon icon={faArrowDown} color="red"/>
		}

		return null
	}, [value, compareValue])
}

export { ValueIcon }
