import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import './assets/styles/App.scss'

function App () {
  return (
    <BrowserRouter>
      <Routes/>
    </BrowserRouter>
  )
}

export default App
