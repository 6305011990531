import {Route, Switch} from 'react-router-dom'
import SignInRedirect from '../views/Auth/SignInRedirect'
import Home from '../views/Home'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/signin-oidc" component={SignInRedirect}/>
      <Route path="/" component={Home}/>
    </Switch>
  )
}
