import { useEffect } from 'react'
import { UserManager } from 'oidc-client'
import { useHistory } from 'react-router-dom'

export default function SignInRedirect() {
  const history = useHistory()

  useEffect(() => {
    new UserManager({ response_mode: 'query'})
      .signinRedirectCallback()
        .then(() => {
          history.push('/')
        })
  }, [])

  return (
    <></>
  )
}
