export const toPosition = int => {
  if (int.toString() === '0') {
    return 'N/A'
  }

  const intArr = int.toString()

  const useThAnyway = intArr.length > 1 && intArr[intArr.length - 2] === '1'

  const suffix = () => {
    if (useThAnyway) {
      return 'th'
    }

    switch (intArr[intArr.length - 1]) {
      case '1':
        return 'st'
      case '2':
        return 'nd'
      case '3':
        return 'rd'
      default:
        return 'th'
    }
  }

  return `${int}${suffix()}`
}

export const formatNumber = value => {
  const numVal = Number(value)

  return !isNaN(numVal) ? numVal.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }) : value
}

export const toGbp = value => `£${formatNumber(value)}`
export const toUsd = value => `$${formatNumber(value)}`;
