import { useEffect, useState } from 'react'
import { getPerformanceRegions, getRegionPerformanceData } from '../utils/functions'
import { useInterval } from 'react-interval-hook'
import { refreshInterval } from './useAsyncData'
import { continentColorScales } from '../utils/chart'
import _ from 'underscore'

const getSortedData = (data) => {
	return _.sortBy(data, region => {
		const { monthTotal } = region
		return monthTotal
	})
		.reverse()
		.filter(region => {
			const { code } = region
			return continentColorScales[code] !== undefined
		})
}

export default function useRegionData() {
	const [regionIds, setRegionIds] = useState([])
	const [data, setData] = useState([])

	useEffect(() => {
		(async () => {
			try {
				const { data } = await getPerformanceRegions()
				const ids = data.map(x => {
					const { id } = x
					return id
				})
				setRegionIds(ids)
			} catch (err) {
				console.error('Unable to load regions', err)
			}
		})()
	}, [])

	useEffect(() => {
		(async () => {
			if (regionIds) {
				await loadRegions()
			}
		})()
	}, [regionIds])

	const loadRegions = async () => {
		if (!regionIds.length) {
			return
		}

		try {
			const promises = regionIds.map(id => getRegionPerformanceData(id))
			const promiseData = await Promise.all(promises)
			const mapped = promiseData.map(x => x.data)

			setData(getSortedData(mapped))
		}
		catch (err) {
			console.error('Unable to load region', err)
		}
	}

	useInterval(loadRegions, refreshInterval, { autoStart: true, immediate: true })

	return { data }
}
