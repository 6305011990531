import { useEffect, useState } from 'react'
import _ from 'underscore'
import { continentColorScales } from '../utils/chart'
import { scaleLinear } from 'd3-scale'
import useAsyncData from './useAsyncData'
import { getMemberMarkers, getPerformanceMapData } from '../utils/functions'

export default function useMapData() {
  const [mapData, setMapData] = useState({})
  const [markerData, setMarkerData] = useState({})
  const { data: map } = useAsyncData(getPerformanceMapData, [])
  const { data: members } = useAsyncData(getMemberMarkers, [])

  useEffect(() => {
    const continentGroups = _.groupBy(map, x => {
      const { continentCode } = x
      return continentCode
    })

    const ranges = Object.keys(continentGroups).reduce((result, key) => {
      const data = continentGroups[key]
      const values = _.sortBy(data.map(x => {
        const { revenue } = x
        return revenue
      }), num => num)

      const domain = [values[0], values[values.length - 1]]
      const range = continentColorScales[key]

      return {
        ...result,
        [key]: {
          colorScale: (value) => scaleLinear().domain(domain).range(range)(value)
        }
      }
    }, {})

    const mapValues = map.reduce((result, item) => {
      const { continentCode, countryCode, revenue } = item
      const { colorScale } = ranges[continentCode]

      return {
        ...result,
        [countryCode]: colorScale(revenue)
      }
    }, {})

    setMapData(mapValues)
  }, [map])

  useEffect(() => {
    const next = members.map(member => {
      const { name, lat, long, isNew } = member
      return {
        name,
        latLng: [lat, long],
        style: {
          fill: isNew ? '#30f23a' : '#ff0000',
          r: isNew ? 7 : 5
        }
      }
    })
    setMarkerData(next)
  }, [members])

  return { mapData, markerData }
}
