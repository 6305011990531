import { useState, useRef, useEffect } from 'react'
import {useInterval} from 'react-interval-hook';
import { useCountUp } from 'react-countup'

export const refreshInterval = parseInt(process.env.REACT_APP_REFRESH_INTERVAL) * 60000

export default function useAsyncData(query, defaultValue) {
  const [data, setData] = useState(defaultValue)
  const loadingRef = useRef(false)

  const fetch = async () => {
    if (loadingRef.current) {
      return;
    }

    loadingRef.current = true;

    try {
      const { data: asyncData} = await query()
      setData(asyncData)
    } catch (err) {
      console.log('error loading data', err)
    } finally {
      loadingRef.current = false;
    }

  }

  useInterval(fetch, refreshInterval, { autoStart: true, immediate: true })

  return { data }
}

export function useAsyncValue(query) {
  const {data} = useAsyncData(query, 0);
  const {countUp, update} = useCountUp({end: data, separator: ','});

  useEffect(() => {
    update(data);
  }, [data, update]);

  return countUp;
}
