import axios from 'axios'

const apiHost = process.env.REACT_APP_API_HOST
const buildUrl = path => `${apiHost}/api/performance/${path}`

const get = async url => await axios.get(url)

export const getPerformanceMapData = async () => await get(buildUrl('map'))
export const getRegionPerformanceData = async (id) => await get(buildUrl(`region/${id}`))
export const getUserPerformanceData = async () => await get(buildUrl('user'))
export const getMemberMarkers = async () => await get(buildUrl('members'))
export const getPerformanceRegions = async () => get(buildUrl('regions'))
export const getTransactingMemberCount = async () => get(buildUrl('transacting-member-count'))
export const getAverageMemberRevenue = async () => get(buildUrl('average-revenue'))

